// Hebrew
export default{
    form: {
        startSurveyButton: "התחל",
        nextButton: "הבא",
        submitAnswerButton: "שלח תשובה",
        continueButton: "המשך",
        finishButton: "סיום",
        headerPercentageCompletion: 'הושלם',
        headerQuestionsCompleted: 'שאלות',
        headerDefaultErrorMessage: 'אופס. משהו השתבש.',
        brandingPoweredBy: 'מופעל על ידי',
        brandingPromoWhySurvey: 'למה סקר כשאפשר להשתמש ב-<b>{product}</b>?',
        brandingPromoGetStarted: 'התחל היום!',
        submissionPending: "שולחים את ה-voiceform שלך. אנא אל תסגור את חלון הדפדפן.",
        submissionCanCloseWindow: "ה-voiceform שלך נשלח בהצלחה. כעת תוכל לסגור את החלון 👍",
        mediaAttachmentOverlayCloseButton: "סגור",
        expiredFormHeader: "תודה שעצרת!",
        expiredFormMessage: "נראה כי הסקר הזה פג תוקף. אנא צור קשר עם יוצר ה-voiceform.",
        notPublishedFormHeader: "ה-voiceform לא פורסם!",
        notPublishedFormMessage: "נראה כי הסקר הזה לא פורסם עדיין. אנא צור קשר עם יוצר ה-voiceform.",
        notPublishedFormRetryButton: "נסה שוב",
        errorFormHeader: "אופס!",
        errorFormMessage: "ייתכן שמשהו השתבש. אנא נסה שוב מאוחר יותר.",
        errorFormRetryButton: "נסה שוב",
        emptyFormHeader: "תודה שעצרת!",
        emptyFormMessage: "אופס! נראה שהטופס הזה ריק כרגע ואין שאלות או שדות למלא. אנו מתנצלים על אי הנוחות. לתיקון הבעיה, אנא צור קשר עם יוצר הטופס. תודה על ההבנה ושיתוף הפעולה שלך!",

        restoreFormHeader: "נראה שיש לך טופס פתוח!",
        restoreFormMessage: "האם ברצונך להמשיך מאיפה שהפסקת?",
        restoreFormButton: "המשך טופס",
        restoreFormButtonNew: "התחל מחדש",

        audioPermissionHeader: "האם תרצה להשיב בקול?",
        audioPermissionMessage: "דיבור יהפוך את המענה למהיר וקל.",
        audioPermissionAcceptButton: "כן, אני רוצה",
        audioPermissionDenyButton: "לא, תודה",
        audioPermissionErrorHeader: "המיקרופון אינו זמין",
        audioPermissionErrorMessage: "אנא בדוק את הרשאות הדפדפן כדי לאפשר גישה למיקרופון.",
        audioPermissionErrorButton: "אישור",
        audioRecorderFailedUpload: "העלאת ההקלטה נכשלה",
        silenceDetectorError: "לא שומעים אותך. בדוק את העדפות המיקרופון שלך.",
        audioRecorderButtonRecord: "הקלט",
        audioRecorderButtonRecordMore: "הקלט עוד",
        voiceResponseSelectionMessage: "בחר כיצד תרצה להשיב...",
        voiceResponseSelectionOrDivider: "או",
        voiceResponseTextInputPlaceholder: "הקלד תשובה",
        voiceResponseTextInputCharacterCounterMinMessage: "אנא הזן לפחות {min} תווים",
        voiceResponseTextInputCharacterCounterCurrentNumberMessage: "{count} תווים (מינימום {min})",
        voiceResponseTextInputCharacterValidationMessage: "{count} תווים (מינימום {min})",

        // checkbox
        checkboxValidationTooFew: "עליך לבחור לפחות {min} אפשרויות",
        checkboxValidationTooMany: "עליך לבחור לא יותר מ-{max} אפשרויות",
        checkboxNoneOfTheAboveOption: "אף אחד מהנ״ל",
        checkboxInvalidAnswer: "תשובה לא חוקית.",

        // datepicker
        datePickerPlaceholder: "בחר תאריך",

        // dropdown
        // email
        emailLabel: "אימייל",
        emailInvalid: "אימייל לא חוקי.",
        // file-upload
        fileUploadPluginNameCamera: "מצלמה",
        fileUploadPluginNameCameraVideo: "הקלט וידאו",
        fileUploadPluginScreenCast: "הקלטת מסך",
        fileUploadVideoImportFilesDefault: 'הקלט או העלה וידאו באמצעות:',
        fileUploadVideoImportFilesLocalFilesDisabled: 'בחר אפשרות הקלטת וידאו:',
        fileUploadVideoImportFilesNoCamera: 'לחץ על הכפתור כדי להקליט וידאו מהמסך',
        fileUploadVideoImportFilesNoScreenCast: 'לחץ על הכפתור כדי להקליט וידאו',
        fileUploadVideoImportFilesNoCameraAndScreenCast: 'אין אפשרויות להקלטת וידאו',

        // matrix
        matrixValidationMessage: "התשובה תקינה.",
        matrixRow: "שורה",
        matrixColumn: "עמודה",
        matrixRowRequired: "השורה {rowTitle} נדרשת.",
        matrixRadioGroup: "קבוצת רדיו",
        matrixCheckboxGroup: "קבוצת תיבות סימון",
        matrixGroupRequired: "עבור השורה {rowTitle}, {type} {groupTitle} נדרש.",
        matrixColumnRequired: "עבור השורה {rowTitle}, העמודה {columnTitle} נדרשת.",
        matrixColumnInvalid: "עבור השורה {rowTitle}, העמודה {columnTitle} אינה תקינה.",
        matrixRequired: "נדרש.",
        matrixNumericMustBeNumber: "חייב להיות מספר.",
        matrixNumericMustBeGreaterThenMin: "חייב להיות גדול מ-{min}.",
        matrixNumericMustBeLessThenMax: "חייב להיות קטן מ-{max}.",
        matrixNumericMustBeInteger: "חייב להיות מספר שלם.",
        matrixNumericInvalidNumber: "מספר לא חוקי.",

        // name
        nameLabel: "שם",
        nameInvalid: "ציין שם פרטי ושם משפחה.",

        // nps
        npsNotLikely: "בכלל לא סביר",
        npsExtremelyLikely: "סביר מאוד",

        // numeric input
        numericInputRequired: "נדרש.",
        numericInputMustBeNumber: "חייב להיות מספר.",
        numericInputMustBeGreaterThenMin: "חייב להיות גדול מ-{min}.",
        numericInputMustBeLessThenMax: "חייב להיות קטן מ-{max}.",
        numericInputMustBeInteger: "חייב להיות מספר שלם.",
        numericInputInvalidNumber: "מספר לא חוקי.",
        numericInputPlaceholder: "הזן מספר",

        // phone
        phoneInvalid: "מספר טלפון לא חוקי.",
        phoneCountrySelectorLabel: 'קידומת מדינה',
        phoneCountrySelectorError: 'בחר מדינה',
        phoneNumberLabel: 'מספר טלפון',
        phoneExample: 'דוגמה:',

        // boolean
        booleanYesLabel: "כן",
        booleanNoLabel: "לא",

        //questionStep
        questionStepAudioQuestionLabel: "שאלת שמע",

        // errors
        invalidPhoneNumber: "{phone} הוא מספר טלפון לא חוקי.",
        invalidEmail: "{email} הוא כתובת אימייל לא חוקית.",
        questionIsRequired: "השאלה נדרשת.",
        answerIsNotValid: "התשובה אינה תקינה.",
        unfinishedProbingDialogError: "אנא השלם את הדיאלוג.",
        cannotResumePartialResponse: "לא ניתן להמשיך תגובה חלקית.",
        failedToSubmitForm: "שליחת הטופס נכשלה. בדוק את חיבור האינטרנט ונסה שוב.",

         //language picker
         searchLanguage: "חפש שפה",
    }
}
